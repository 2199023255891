import React from 'react';
import styled from 'styled-components';

import { MQ } from '@rover/kibble/styles';
import HeroWithButton from '@rover/react-lib/src/components/heroes/HeroWithButton';
import heroSource from '@rover/react-lib/src/images/careers/home/hero_careers_home_desktop.jpg';

const StyledHero = styled(HeroWithButton)`
  h1 {
    width: 270px;
    margin-left: auto;
    margin-right: auto;
  }

  a {
    width: 200px;
    font-size: 16px;
  }

  ${MQ.NOT_XS.toString()} {
    h1 {
      width: 400px;
    }
  }

  ${MQ.MD_UP.toString()} {
    h1 {
      width: auto;
    }
  }
`;

const Header = (): JSX.Element => (
  <StyledHero
    headlineText="Join the team where tech meets heart"
    buttonText="Search job openings"
    imageSrc={heroSource}
    mobileHeight={300}
    tabletHeight={360}
    desktopHeight={360}
    buttonTo="/careers/search/"
  />
);

export default Header;
