import React from 'react';
import { Helmet } from 'react-helmet';

import ScrollToTopOnMount from '@rover/react-lib/src/components/utils/ScrollToTopOnMount';
import engineeringImgSrc from '@rover/react-lib/src/images/careers/home/engineering_callout_picture.jpg';

import Navigation from '../common/Navigation';
import { CareersPageLayout } from '../common/styles';
import { prefetchLeverJobs } from '../hooks/leverJobs';

import CalloutSection from './components/CalloutSection';
import Header from './components/Header';

const CareersHomePage = (): JSX.Element => (
  <>
    <Helmet>
      <title>Careers at Rover.com</title>
      <meta
        name="description"
        content="Rover is hiring experienced data and software engineers for our technical team."
      />
      <meta property="og:image" content={engineeringImgSrc} />
      <meta name="twitter:image" content={engineeringImgSrc} />
    </Helmet>

    <div lang="en-us">
      <ScrollToTopOnMount />
      <Header />
      <CareersPageLayout>
        <Navigation />
        <CalloutSection />
      </CareersPageLayout>
    </div>
  </>
);

CareersHomePage.reactQueryPrefetch = prefetchLeverJobs;
export default CareersHomePage;
