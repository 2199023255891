import React from 'react';

import { Box } from '@rover/kibble/core';
import MediaCallout from '@rover/react-lib/src/components/callouts/MediaCallout';
import type { Props as MediaCalloutProps } from '@rover/react-lib/src/components/callouts/MediaCallout/MediaCallout';
import engineeringImgSrc from '@rover/react-lib/src/images/careers/home/engineering_callout_picture.jpg';
import cultureImgSrc from '@rover/react-lib/src/images/careers/perks/culture_callout_picture.jpg';

const callouts: MediaCalloutProps[] = [
  {
    layout: 'normal',
    title: 'Make a real impact',
    text: 'Rover’s global platform makes it easy for pet parents to connect with trusted pet sitters and dog walkers. We’re breaking new ground with an innovative and mission-driven product: the first-to-market pet care marketplace.',
    buttonLabel: 'Explore Careers',
    youtubeVideoId: 'zoiEFJM1BcU',
    buttonLinkPath: '/careers/search/',
  },
  {
    layout: 'reverse',
    title: 'Engineering at Rover',
    text: 'Rover is hiring data and software engineers at all different levels.',
    buttonLabel: 'Explore Engineering Careers',
    image: engineeringImgSrc,
    buttonLinkPath: '/careers/engineering/',
    description: 'engineers looking at a computer screen',
  },
  {
    layout: 'normal',
    title: 'Perks & Culture',
    text: "We celebrate each other's successes and we've built an inclusive culture of humor, fun, and yes, pets.",
    buttonLabel: 'Learn about life at Rover',
    image: cultureImgSrc,
    buttonLinkPath: '/careers/perks-and-culture/',
    description: 'group of rover employees at a convention together',
  },
];

const CalloutSection = (): JSX.Element => (
  <Box mt="12x">
    {callouts.map((callout, key) => (
      <MediaCallout {...callout} key={key} />
    ))}
  </Box>
);

export default CalloutSection;
