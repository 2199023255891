import React from 'react';

import type { Props as ButtonProps } from '@rover/kibble/core/Button';
import Button from '@rover/kibble/core/Button';

import { asRouterLink } from '../../utils/RouterLink';
import Hero from '../Hero';

export type Props = {
  buttonHref?: string;
  buttonText: string;
  buttonProps?: ButtonProps;
  buttonTo?: string;
  headlineText: string;
  headlineDataId?: string;
  subHeadlineText?: string;
  subHeadlineDataId?: string;
  imageSrc: string;
  mobileImageSrc?: string;
  onClick?: () => void;
  desktopHeight?: number;
  tabletHeight?: number;
  mobileHeight?: number;
};

const HeroWithButton = ({
  buttonHref,
  buttonText,
  buttonProps,
  buttonTo,
  headlineText,
  subHeadlineText,
  imageSrc,
  mobileImageSrc,
  onClick,
  ...other
}: Props): JSX.Element => {
  const cta = (
    <Button
      {...buttonProps}
      href={buttonHref}
      onClick={onClick}
      variant="primary"
      as={buttonTo ? asRouterLink(buttonTo) : undefined}
      data-qa-id="hero-button"
    >
      {buttonText}
    </Button>
  );
  const heroProps = {
    ...other,
    cta,
    headlineText,
    subHeadlineText,
    imageSrc,
    mobileImageSrc,
  };

  return <Hero {...heroProps} />;
};

export default HeroWithButton;
