import type { ReactElement } from 'react';
import React from 'react';
import YouTube from 'react-youtube';
import { MessageDescriptor } from '@lingui/core';
import { Trans } from '@lingui/react';
import styled from 'styled-components';

import { Button, Image } from '@rover/kibble/core';
import { MQ } from '@rover/kibble/styles';

import ErrorBoundary from '../../errorHandling/ErrorBoundary';
import { asRouterLink } from '../../utils/RouterLink';

const ASPECT_RATIO = 16 / 9;
const DEFAULT_VIDEO_WIDTH = 652;
const DEFAULT_VIDEO_HEIGHT = DEFAULT_VIDEO_WIDTH / ASPECT_RATIO;
const YOUTUBE_OPTS = {
  width: DEFAULT_VIDEO_WIDTH.toString(),
  height: DEFAULT_VIDEO_HEIGHT.toString(),
};
type CalloutWrapperProps = {
  layout: string;
};
const CalloutWrapper = styled.div<CalloutWrapperProps>`
  display: flex;
  justify-content: space-between;
  max-width: 1000px;
  margin: 0 -15px 50px;

  ${MQ.XS.toString()} {
    flex-direction: column;
  }

  ${MQ.SM_UP.toString()} {
    flex-direction: ${(props) => (props.layout === 'reverse' ? 'row-reverse' : 'row')};
  }

  ${MQ.MD_UP.toString()} {
    margin: 0 auto 50px;
  }
`;
const Media = styled.div<Record<string, unknown>>`
  flex: 2;

  ${MQ.SM_UP.toString()} {
    padding: 0 15px;
  }
`;
const YouTubeWrapper = styled.div<Record<string, unknown>>`
  float: none;
  clear: both;
  width: 100%;
  position: relative;
  padding-bottom: 50%;
  padding-top: 25px;
  height: 0;

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
const Callout = styled.div<Record<string, unknown>>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 15px;

  ${MQ.XS.toString()} {
    margin-top: 50px;
  }
`;
const Title = styled.h2<Record<string, unknown>>`
  margin-top: 0;
  font-size: 26px;
  font-weight: 500;
  &:after {
    margin-top: 15px;
    display: block;
    content: '';
    width: 70px;
    border-top: 3px solid #00bd70;
  }
`;
const Text = styled.p<Record<string, unknown>>`
  width: 100%; // Because IE11 doesn't know how to flex
  font-size: 16px;
  margin: 20px 0 25px 0;
`;

type MediaCalloutCommonProps = {
  buttonHref?: string;
  buttonLinkPath?: string;
  buttonLabel: string | MessageDescriptor;
  layout?: string;
  onClick?: () => void;
  text: string | MessageDescriptor;
  title: string | MessageDescriptor;
  youtubeVideoId?: string | null;
};
type MediaCalloutWithImageProps = MediaCalloutCommonProps & {
  description: string;
  image: string;
  imageHeight?: string;
};
export type Props = MediaCalloutWithImageProps | MediaCalloutCommonProps;

const renderText = (text): ReactElement | null => {
  if (!text) return null;

  if (React.isValidElement(text)) {
    return text;
  }

  return <Trans id={text} />;
};

const MediaCallout = ({
  buttonHref = '',
  buttonLinkPath,
  buttonLabel,
  layout = 'normal',
  onClick = () => {},
  text,
  title,
  youtubeVideoId = null,
  ...other
}: Props): ReactElement => {
  let mediaImage;
  if ('image' in other) {
    mediaImage = (
      <Media>
        <Image
          src={other.image}
          description={other.description}
          height={other.imageHeight || 'auto'}
          width="100%"
        />
      </Media>
    );
  }
  return (
    <ErrorBoundary location="MediaCallout">
      <CalloutWrapper layout={layout}>
        {mediaImage}
        {youtubeVideoId && (
          <Media>
            <YouTubeWrapper>
              <YouTube videoId={youtubeVideoId} opts={YOUTUBE_OPTS} />
            </YouTubeWrapper>
          </Media>
        )}
        <Callout>
          <Title>{renderText(title)}</Title>
          <Text>{renderText(text)}</Text>
          <Button
            href={buttonHref}
            as={buttonLinkPath ? asRouterLink(buttonLinkPath) : undefined}
            onClick={onClick}
            variant="primary"
            size="small"
          >
            {renderText(buttonLabel)}
          </Button>
        </Callout>
      </CalloutWrapper>
    </ErrorBoundary>
  );
};

export default MediaCallout;
